import {
  ICartProduct,
  IConditionAction,
  ITransformCartProduct,
  ITransformCartProductsParams,
} from '../../interfaces'
import { getActivePromotionsFields } from '../common/getPromotionFields'
import {
  getPromotionDetailsByType,
  promotionsMap,
} from './getPromotionDetailsByType'
import { validateAndTransformActivePromotion } from './validateAndTransformActivePromotion'

export const transformProductArray: IConditionAction<
  ITransformCartProduct,
  ICartProduct
>[] = [
  {
    condition: ({
      activePromotionFields: {
        fields: { customerGetsItems },
      },
      cartProduct: { sku },
    }) => !customerGetsItems?.includes(sku),
    transformAction: ({ cartProduct }) => cartProduct,
  },
  {
    condition: ({
      activePromotionFields: {
        fields: { type, customerGetsQuantity },
      },
      cartProduct,
      activePromotion,
      config,
    }) =>
      !!activePromotion &&
      !!promotionsMap[type](!!customerGetsQuantity)({
        activePromotion,
        cartProduct,
        config,
      }),
    transformAction: getPromotionDetailsByType,
  },
]

/**
 * Function declaration to set promotions ui details to be shown on cart
 *
 * @internal
 * @param product - The cart product to be updated
 * @param categories - The categories
 * @returns IProduct updated with promotion ui details
 */
export const setPromotionUiDetailsCart = ({
  cartProducts,
  activePromotions,
  config,
}: ITransformCartProductsParams): ICartProduct[] => {
  const result = activePromotions?.reduce<ICartProduct[]>(
    (currentItems, activePromotion) => {
      const updatedActivePromotion = validateAndTransformActivePromotion({
        cartProducts,
        activePromotion,
      })
      const activePromotionFields = getActivePromotionsFields(
        updatedActivePromotion,
      )
      return currentItems.map(
        (cartProduct) =>
          transformProductArray
            .find(({ condition }) =>
              condition({
                activePromotionFields,
                cartProduct,
                activePromotion,
                config,
              }),
            )
            ?.transformAction({
              activePromotionFields,
              cartProduct,
              activePromotion,
              config,
              cartProducts: currentItems,
            }) || cartProduct,
      )
    },
    cartProducts,
  )
  return result
}
