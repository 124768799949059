import {
  ActivePromotion,
  PromotionCustomerGets,
  PromotionType,
} from '../../graphQL/commons'
import {
  ICartProduct,
  IValidateActivePromotionParams,
} from '../../interfaces/cart'

interface ICustomerGetItemsConditionParams {
  cartProducts: ICartProduct[]
  items: PromotionCustomerGets['items']
  type: PromotionType
}

export const WILDCARD = '*'

const transformCustomerGetsItems = ({
  items,
  type,
  cartProducts,
}: ICustomerGetItemsConditionParams): string[] => {
  if (
    items?.length === 1 &&
    items[0] === WILDCARD &&
    type === PromotionType.VOLUME
  ) {
    return cartProducts.map(({ sku }) => sku)
  }
  return items || []
}

export const validateAndTransformActivePromotion = ({
  cartProducts,
  activePromotion,
}: IValidateActivePromotionParams): ActivePromotion => {
  const {
    promotion: {
      customerGets: { items, ...restCustomerGets },
      type,
      ...restPromotion
    },
    ...restActivePromotion
  } = activePromotion

  const transformedCustomerGetsItems = transformCustomerGetsItems({
    cartProducts,
    type,
    items,
  })

  return {
    ...restActivePromotion,
    promotion: {
      type,
      ...restPromotion,
      customerGets: {
        ...restCustomerGets,
        items: transformedCustomerGetsItems,
      },
    },
  }
}
